import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import './theme/index.css'
Vue.use(ElementUI)
// import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

// import './assets/font/fontsize/iconfont.css'
import './style/index.scss' // global css
import local from './util/local'
Vue.prototype.$local = local
import util from './util/util'
Vue.prototype.$util = util

import api from '@/api/api'
Vue.prototype.$api = api
import axios from 'axios'
Vue.prototype.$axios = axios

let basUrl = 'https://newapi.aoxiangae.com' // 测试
Vue.prototype.$PayNameImg= 'test';
let PayName = 'new测试s'

// let basUrl = 'https://sapi.smarkpay.com' // mark
// Vue.prototype.$PayNameImg= 'mark';
// let PayName = 'mark商户后台'

// let basUrl = 'https://atapi.avpay666.com' // avatar
// Vue.prototype.$PayNameImg= 'mark';
// let PayName = 'avatar商户'

// let basUrl = 'http://api.btjl888.com' // 币通
// Vue.prototype.$PayNameImg= 'bitong';
// let PayName = '币通商户'


// let basUrl = 'https://ybapi.ybzypay.com' // 渝邦
// Vue.prototype.$PayNameImg= 'yubang';
// let PayName = '渝邦'

// let basUrl = 'https://api.qxpay999.com' // 777
// Vue.prototype.$PayNameImg= '柒星';
// let PayName = '柒星'

// let basUrl = 'https://aiapi.aipay666.com' // 爱付
// Vue.prototype.$PayNameImg= 'aipay';
// let PayName = '爱付'

// let basUrl = 'https://rbapi.qixingapay.com' // RbPay
// Vue.prototype.$PayNameImg= 'rb';
// let PayName = 'RbPay'


Vue.prototype.$baseURL= basUrl;
import md5 from 'js-md5';
Vue.prototype.$md5= md5;
import QRCode from 'qrcodejs2'
Vue.prototype.$QRCode= QRCode;

let hasSide = 'true'
Vue.prototype.$hasSide = hasSide
Vue.config.productionTip = false


router.beforeEach((to,from,next)=>{
	document.title = PayName
	next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

